export const HexagonsDescription = [
  {
    title: "RodeApps",
    description:
      "One of our most loyal customers and one of the first ones as well. Our collaboration spreads over many years and projects of all kinds  (web front/web back/mobile end) and sizes, but we’ve always delivered and we’ve always made them happy. The proof is the long-lasting partnership that has grown over this time.",
  },

  {
    title: "Foraga",
    description:
      "“World’s best debates start here.” \n\nThe company was looking for a senior React developer to do the heavy lifting for a MVP of an online PR skills training platform similar to Udemy. We delivered and had great results.",
  },

  {
    title: "SNOWSOS",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. A quam mus vulputate enim est. Viverra lectus dui, lacinia at. Sed pellentesque egestas vestibulum in.",
  },
  {
    title: "Fintech Solutions",
    description:
      "“Building the best digital financial advisory solution - with a human touch” \n\nA german Fintech startup and one of our clients; they are building software for financial counsel firms. We’ve helped with the development of their front-end modules for over a year and had great feedback.",
  },
  {
    title: "LinkGraph.io",
    description:
      "“The Best SEO And Digital Marketing Agency” \n\nWe’ve helped them build their product. Collaborating with their existing team on both back and front end, we had an important contribution to their core SEO and BigData solution. ",
  },

  {
    title: "RentInsider",
    description:
      "“The renting world’s source of truth” \n\nOur revolutionary in-house built B2C SaaS platform.It is a place for tenants to review their rents and landlords or for landlords to review their tenants, while at the same time being able to see the historical background of all the entities involved in the renting process (tentants/landlords/\nproperties).",
  },

  {
    title: "TacitKnowledge",
    description:
      "“We make technology an enabler for brands and retailers.”\n\nA large outsourcing agency specialised in e-commerce. We lead the development of one of their front-end applications for one of their largest customers and they were delighted with us.",
  },
  {
    title: "Polyconseil",
    description: "A french software development company building mostly crypto-currency and fintech related products. We provide them with front end developers and they are extremely happy with us."
  },
  {
    title: "Pinnacle Insurance",
    description: "A North American insurance company. We’ve built an innovative solution from scratch for them called “SnowSOS” .The product is a platform for snow plowers to get insurance from and it was designed as an MVP for generic online commercial insurance and coverage services. It has both Web and Native Mobile Application elements.",
  }
];
