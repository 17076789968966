import React from "react";
import "./styles/OverlayDesktop.scss";

export default function OverlayDesktop({ onClick, spideHeight }) {
  return (
    <div className="overlayDesktopContainer" onClick={onClick}>
   
    </div>
  );
}

