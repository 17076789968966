import "./styles/WhatWeDo.scss";
import PageTitle from "../../components/PageTitle/PageTitle";
import { React, useEffect } from "react";
import spiders from "../../assets/spiders/spiderDesk.png";
import OverlayDesktop from "../Overlays/OverlayDesktop";
import { ImageMap } from "@qiuz/react-image-map";
import { ReactComponent as SeeMoreIcon } from "../../assets/seeMoreMobileSvg.svg";
import { useWhatWeDo } from "./hooks";
import deskGif from "../../assets/spiders/deskGrey.gif";
import OverlayMobile from "../Overlays/OverlayMobile";
export default function WhatWeDo() {
  const {
    isVisibleDesktop,
    isVisibleMobile,
    spiderDesk,
    mapArea,
    onMapClick,
    closeOverlayDesktop,
    removeScroll,
    mapAreaMobile,
    addScroll,
    onMapClickMobile,
    closeOverlay,
    spiderOverlay,
  } = useWhatWeDo();
  useEffect(() => {
    isVisibleMobile ? removeScroll() : addScroll();
  }, [isVisibleMobile, removeScroll, addScroll]);
  return (
    <>
      <section id="whatwedo">
        <div
          className="WhatWeDo"
          onClick={isVisibleDesktop ? closeOverlayDesktop : () => {}}
        >
          <PageTitle
            description="Have a glimpse at what we do"
          />
          <div className="whatWeDoContainer">
            <ImageMap
              src={isVisibleDesktop ? deskGif : spiders}
              map={mapArea}
              onMapClick={onMapClick}
            />
          </div>
        </div>
        <div className="whatWeDoMobile">
          <PageTitle
            description="Have a glimpse at what we do"
          />
          <ImageMap
            className="usage-map"
            src={spiderDesk}
            map={mapAreaMobile}
            onMapClick={onMapClickMobile}
          />
          <div className="extraordinaryMobileSizeContainer">
            <div className="mobileTitle">AI/NLP/Data Analysis & Visualization</div>
            <span className="seeMoreMobileSize">
              <span className="seeMoreSpace">SEE MORE</span>
              <SeeMoreIcon style={{ verticalAlign: "middle" }} />
            </span>
          </div>
          <div className="mobileMobileSizeContainer">
            <div className="mobileTitle">Mobile</div>
            <span className="seeMoreMobileSize">
              <span className="seeMoreSpace">SEE MORE</span>
              <SeeMoreIcon style={{ verticalAlign: "middle" }} />
            </span>
          </div>
          <div className="webMobileSizeContainer">
            <div className="mobileTitle">Web</div>
            <span className="seeMoreMobileSize">
              <span className="seeMoreSpace">SEE MORE</span>
              <SeeMoreIcon style={{ verticalAlign: "middle" }} />
            </span>
          </div>
          {isVisibleMobile && (
            <OverlayMobile onClick={closeOverlay} spider={spiderOverlay} />
          )}
        </div>
      </section>
      {isVisibleDesktop && <OverlayDesktop onClick={closeOverlayDesktop} />}
    </>
  );
}
