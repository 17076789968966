import WhatWeDo from "./WhatWeDo/WhatWeDo";
import NavBar from "../components/NavBar/NavBar";
import { Partners } from "../screens/Partners/Partners";
import { AboutUs } from "./AboutUs/AboutUs";
import { ContactUs } from "./ContactUs/ContactUs";
import { Footer } from "../components/Footer/Footer";
import { useAfterIntroLayout } from "./hooks";
import { HomePage } from "./HomePage/Homepage";
import { TechStack } from "./TechStack/TechStack";
import { HowWeHelp } from "./HowWeHelp/HowWeHelp";

export const AfterIntroLayout = () => {
  const {} = useAfterIntroLayout();

  return (
    <div id="HomePage" className="animationsTrigger">
      <>
        <NavBar />
      </>
      <>
        <HomePage />
        <HowWeHelp />
        <WhatWeDo />
        <TechStack />
        <Partners />
        <AboutUs />
        <ContactUs />
        <Footer />
      </>
    </div>
  );
};
