import { ReactComponent as Logo } from "../../assets/logo.svg";
import "./styles/NavBar.scss";
import Menu from "./components/Menu/Menu";
import { ReactComponent as Hamburger } from "../../assets/hamburger.svg";
import { ReactComponent as Xicon } from "../../assets/xicon.svg";
import { useNavBar } from "./hooks";

const NavBar = ({ isOnOverlay, onClick }) => {
  const { isOpen, toggleHamburgerMenu,scrollToTop} = useNavBar();

  return (
    <>
      <div className={!isOnOverlay ? "navBar" : "navBarOverlay"}>
        <Logo onClick={scrollToTop} className="mobileLogoSvg" />

        <nav className="menuPosition"  role="navigation">
          <Menu isOnOverlay={isOnOverlay} toggleHamburgerMenu={toggleHamburgerMenu} mobileMenu={isOpen}/>
        </nav>
        <button
          className="hamburgerBtn"
          onClick={isOnOverlay ? onClick : toggleHamburgerMenu}
        >
          {!isOnOverlay && !isOpen ? <Hamburger className="hamburger"/> : <Xicon className="xIcon"/>}
        </button>
      </div>
    </>
  );
};
export default NavBar;
