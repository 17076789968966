import "./styles/IntroScreen.scss";
import { ReactComponent as SpiderLogo } from "../../assets/spider-logo.svg";
import { ReactComponent as SpiderLinkedText } from "../../assets/spiderlinked.svg";

const IntroScreen = ({ className = "" }) => {
  return (
    <div className="IntroScreen">
      <div className={"animationContainer " + className}>
        <>
          <div className="spiderLogoContainer">
            <SpiderLogo className="spiderLogoSvg" />
            <div className="spiderLinkedTextContainer">
              <SpiderLinkedText className="spiderLinkedTextSvg" />
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default IntroScreen;
