import emailjs from "@emailjs/browser";
import { useCallback, useEffect } from "react";

import { useState, useRef } from "react";

export const useContactUs = () => {
  const form = useRef();

  const getMessageData = (e) => {
    setCharacterCount(e.target.value.length);
    setMessageData(e.target.value);
  };

  const [characterCount, setCharacterCount] = useState(0);
  const [messageData, setMessageData] = useState();
  const isUSLink = useRef(false);

  const [isMailSent, setIsMailSent] = useState(false);

  useEffect(() => {
    if (window?.location?.href.includes('spiderlinked-us')) {
      isUSLink.current = true;
    }
  }, [])

  //email js service

  const sendEmail = useCallback(
    (e) => {
      e.preventDefault();
      emailjs
        .sendForm(

          `${process.env.REACT_APP_EMAIL_SERVICE_KEY}`,
           `${process.env.REACT_APP_EMAIL_TEMPLATE}`,
          form.current,
          `${process.env.REACT_APP_EMAIL_USER_ID}`
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      setIsMailSent(true);
    },
    [setIsMailSent]
  );

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };


  return {
    characterCount,
    getMessageData,
    messageData,
    isMailSent,
    form,
    sendEmail,
    openInNewTab,
    isUSLink,
  };
};
