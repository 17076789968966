import PageTitle from "../../components/PageTitle/PageTitle";

import "./styles/AboutUs.scss";

export const AboutUs = () => {
    return (
      <section  id="contactus" className="AboutUs">
        <div className="aboutUsTitle">
          <PageTitle title="About us" description="Get to know us better" />
        </div>
        <main className="aboutUsDescription">
          Spiderlinked is a flexible, dynamic team of developers focused on providing simple solutions to complex problems. Our goal is to use all aspects of technology to improve efficiency, communication and comprehension for our clients. Whether you’re looking for help with a small integration, implementation or extension project, or you need an end-to-end solution to support your business, we would love to help you achieve your goals.
        </main>
      </section>
    );
  };
