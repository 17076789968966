import React from "react";
import "./PageTitle.scss";

const PageTitle = ({ title, description }) => (
  <div className="PageTitle">
    { title ? <span role="heading" aria-level="1" className="title">{title}</span> : null }
    <span className="description">{description}</span>
  </div>
);

export default PageTitle;
