export const useHowWeHelp = () => {
    const slides = [
        {
            title: "Mobile/Web App",
            description: "Spiderlinked US was approached by an insurtech startup that had a stillborn web/mobile app and a poor experience with their development resource. The previous dev team had over a year and a half into the project, and had not produced a working product. Spiderlinked US took over development work in August and was able to take the code from a basic prototype to the first live customer transaction by December. Two years on, Spiderlinked continues to support development and maintenance for this innovative solution.",
        },
        {
            title: "GIS (Geographic Information System) Solution",
            description: "An EV Charging group had a prototype ArcGIS tool, but their part-time developer was pulled into a full-time role at a different company and was unable to continue work. Spiderlinked US took over development efforts, and converted static data layers with 2 year-old data sources to dynamic data layers that are updated monthly. The entire project was handed over to the client on completion with source-controlled code, and full documentation.",
        },
        {
            title: "Integration Support",
            description: "A healthcare company with a Microsoft tech stack wanted to integrate and extend the functionality of a javascript webviewer into their product, but lacked the development resources to execute. Spiderlinked US provided them with a dedicated developer that set up a sandbox environment for development and testing of the webviewer. The developer successfully implemented the webviewer and coded the extensions that the client requested. The webviewer is up and running in the customer’s production environment, and the developer is continuing to support additional enhancement requests from the client.",
        },
        {
            title: "Data Migration",
            description: "A healthcare company was acquiring four new customers a month. They didn’t have the development capacity to handle migrating their customer’s data from the old systems. Spiderlinked US provided an ETL developer and QA person to handle these tasks and alleviate the growing pains of the client. The client was able to focus efforts on sales and feature development, while keeping up with onboarding needs.",
        },
    ];

    return {
        slides,
    };
};
