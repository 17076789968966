import { INTRO_SCREEN_DISPLAY_TIMEOUT } from "./utils/constants";
import { useState, useEffect } from "react";

export const useApp = () => {
    
    const [isIntroScreenVisible, setIsIntroScreenVisible] = useState(true);
    const [shouldStartFadingLogo, setShouldStartFadingLogo] = useState(false);
    const [isAfterIntroLayout, setIsAfterIntroLayout] = useState(false);
  
    function setStorageKey(key, ttl) {
      const now = new Date();
      localStorage.setItem(key, now.getTime() + ttl);
    }
  
    function removeStorageKey(key) {
      const itemStr = localStorage.getItem(key);
  
      const item = JSON.parse(itemStr);
      const now = new Date();
  
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
      }
    }
  
    useEffect(() => {
      if (INTRO_SCREEN_DISPLAY_TIMEOUT ) {
        removeStorageKey("IntroScreenDisplayTimeout");
        setIsIntroScreenVisible(false);
        setIsAfterIntroLayout(true);
      } else {
        setStorageKey("IntroScreenDisplayTimeout", 86400000);
      }
    }, []);
  
    useEffect(() => {
      setTimeout(() => setIsIntroScreenVisible(false), 3890);
      setTimeout(() => setShouldStartFadingLogo(true), 3300);
      setTimeout(() => setIsAfterIntroLayout(true), 3875);
    }, []);

    return {
        shouldStartFadingLogo,
        isAfterIntroLayout,
        isIntroScreenVisible,
    }
}
