import React from "react";
import "./styles/OverlayPartners.scss";
import NavBar from "../../components/NavBar/NavBar";

export default function OverlayPartners({
  onClick,
  partnersTitle,
  partnersDescription,
  partnersLogo,
  href,
  onContentClick,
}) {
  return (
    <div className={"overlayPartnersContainer"}>
      <NavBar isOnOverlay onClick={onClick} />

      <div>
        <div className="overlayPartnersDescriptionContainer">
          <div className="overlayPartnersText" style={{cursor: href ? "pointer" : "default"}} onClick={onContentClick || (() => {})}>
            <div className="partnersLogo">{partnersLogo}</div>
            <div className="overlayPartnersTitle">{partnersTitle}</div>
            {partnersDescription}
          </div>
        </div>
      </div>
    </div>
  );
}
