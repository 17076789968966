import "./styles/Footer.scss";

export const Footer = () => {
  return (
    <div className="spiderLinkedFooter">
      <div className="cookies">
      </div>
      <div className="footerText">©2021 SpiderLinked</div>
    </div>
  );
};
