import "./styles/ContactUs.scss";
import { useContactUs } from "./hooks";
import PageTitle from "../../components/PageTitle/PageTitle";
import spider from "../../assets/SpiderFormDesktop.png";
import { ReactComponent as LocationIcon } from "../../assets/locationIcon.svg";
import { ReactComponent as EmailIcon } from "../../assets/emailIcon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/phoneIcon.svg";
import { ReactComponent as FacebookIcon } from "../../assets/fbicon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/linkdin.svg";
import { ReactComponent as GitHubIcon } from "../../assets/github.svg";
import { ReactComponent as SubmitIcon } from "../../assets/submitIcon.svg";


export const ContactUs = () => {
  const {
    characterCount,
    getMessageData,
    messageData,
    isMailSent,
    form,
    sendEmail,
    openInNewTab,
    isUSLink,
  } = useContactUs();

  return (
    <div
      className="ContactUs"
      style={{
        display: "flex",
        justifyContent: isMailSent ? "" : "space-between",
      }}
    >
      <div className="contactUsTitle">
        <PageTitle
          title="Contact us"
          description="What can we help you with?"
        />

        {!isUSLink.current ?
          (
            <div className="socialInfo">
              <div className="branch">
                <span className="title">Romania</span>
                <LocationIcon className="socialIcon" />
                <span>Brasov, Romania</span>
              </div>
              <div style={{ marginLeft: "-5px" }}>
                <EmailIcon className="socialIcon" />
                <span>office@spiderlinked.com</span>
              </div>
              <div style={{ marginLeft: "-5px" }}>
                <PhoneIcon className="socialIcon" />
                <span>+4072.059.5702</span>
              </div>
              <div className="branch">
                <span className="title">United States of America</span>
                <LocationIcon className="socialIcon" />
                <span>Austin, Texas, US</span>
              </div>
              <div style={{ marginLeft: "-5px" }}>
                <EmailIcon className="socialIcon" />
                <span>jon@spiderlinked-us.com</span>
              </div>
              <div style={{ marginLeft: "-5px" }}>
                <PhoneIcon className="socialIcon" />
                <span>+1512.270.1522</span>
              </div>
            </div>
          )
        :
          (
            <div className="socialInfo">
              <div className="branch">
                <span className="title">United States of America</span>
                <LocationIcon className="socialIcon" />
                <span>Austin, Texas, US</span>
              </div>
              <div style={{ marginLeft: "-5px" }}>
                <EmailIcon className="socialIcon" />
                <span>jon@spiderlinked-us.com</span>
              </div>
              <div style={{ marginLeft: "-5px" }}>
                <PhoneIcon className="socialIcon" />
                <span>+1512.270.1522</span>
              </div>
              <div className="branch">
                <span className="title">Romania</span>
                <LocationIcon className="socialIcon" />
                <span>Brasov, Romania</span>
              </div>
              <div style={{ marginLeft: "-5px" }}>
                <EmailIcon className="socialIcon" />
                <span>office@spiderlinked.com</span>
              </div>
              <div style={{ marginLeft: "-5px" }}>
                <PhoneIcon className="socialIcon" />
                <span>+4072.059.5702</span>
              </div>
            </div>
          )
        }
        <div className="socialIcons">
          <FacebookIcon  onClick={() =>openInNewTab("https://www.facebook.com/SpiderLinked-100359925423434/")} />
          <TwitterIcon onClick={() =>openInNewTab("https://twitter.com/SpiderLinked")}/>
          <LinkedInIcon onClick={() =>openInNewTab("https://www.linkedin.com/company/spiderlinked")}/>
          <GitHubIcon onClick={() =>openInNewTab("https://github.com/orgs/SpiderLinked/dashboard")}/>
        </div>
      </div>

      {!isMailSent ? (
        <div id="formContainer">
          <form id="contactUsForm" ref={form} onSubmit={sendEmail} aria-label="Contact us">
            <div className="name">
              <label htmlFor="name"></label>
              <input
                autoComplete="false"
                type="text"
                placeholder="First Name"
                name="name"
                id="name_input"
                required
              />
            </div>
            <div className="lname">
              <label htmlFor="lname"></label>
              <input
                autoComplete="false"
                type="lname"
                placeholder="Last Name"
                name="lname"
                id="lname_input"
                required
              />
            </div>

            <div className="subject">
              <label htmlFor="subject"></label>
              <input
                autoComplete="false"
                type="subject"
                placeholder="Subject"
                name="subject"
                id="subject"
                required
              />
            </div>
            <div className="email">
              <label htmlFor="email"></label>
              <input
                autoComplete="false"
                type="email"
                placeholder="Email"
                name="email"
                id="email"
                required
              />
            </div>

            <div className="message">
              <label htmlFor="message"></label>
              <textarea
                name="message"
                maxLength="200"
                placeholder="Write a message"
                id="message_input"
                cols="30"
                rows="5"
                value={messageData}
                onChange={getMessageData}
                required
              ></textarea>
              <div className="characterCount">{`${
                200 - characterCount
              }/200 characters left`}</div>
              <div
                className="progressBar"
                style={{
                  width: `${characterCount / 2}%`,
                  marginBottom: "4px",
                }}
              ></div>
            </div>
            <div className="submit">
              <button type="submit" id="form_button" aria-label="Submit message">
                <span className="text">Send Message</span>
                <SubmitIcon className="icon" />
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div>
          <div
            className="formSpiderDesktop"
            style={{
              marginTop: "-40px",
              left: isMailSent && "90%",
              position: "relative",
            }}
          >
            <div className="bubble">
              <div className="bubbleDescription">
                <div className="webster">Webster</div>Message sent! Our team
                will get back at you!
              </div>
            </div>

            <img
              className="formSpiderPictureDesktop"
              src={spider}
              alt="spider"
            />
          </div>

          <div className="formSpiderMobile">
            <div className="bubbleMobile">
              <div className="bubbleMobileDescription">
                <div className="webster">Webster</div> Message sent! Our team
                will get back at you!
              </div>
            </div>
            <img className="formSpiderPictureMobile" src={spider} alt="spider" />
          </div>
        </div>
      )}
    </div>
  );
};
