import { useState, useEffect } from "react";
import spiderDesk from "../../assets/spiders/spidersOnDeskMobile.png";
import extraordinarySpiderGlow from "../../assets/spiders/extraordinarySpiderGlow.png";
import mobileSpiderGlow from "../../assets/spiders/mobileSpiderGlow.png";
import webSpiderGlow from "../../assets/spiders/webSpiderGlow.png";
import { ReactComponent as SeeMoreIcon } from "../../assets/seeMoreIconDesktop.svg";
import { ReactComponent as XIcon } from "../../assets/xIconOverlay.svg";
import { Carousel } from 'react-responsive-carousel';


export const useWhatWeDo = () => {
  const [isVisibleMobile, setIsVisibleMobile] = useState(false);
  const [isMobileActive, setIsMobileActive] = useState(false);
  const [isExtraordinaryActive, setIsExtraordinaryActive] = useState(false);
  const [isWebActive, setIsWebActive] = useState(false);
  const [isVisibleDesktop, setIsVisibleDesktop] = useState(false);
  const [spiderOverlay, setSpiderOverlay] = useState(null);

  const closeOverlay = () => {
    document.getElementsByTagName("html")[0].style = "overflow: visible;";
    setIsVisibleMobile(false);
  };

  const closeOverlayDesktop = () => {
    document.getElementsByTagName("html")[0].style = "overflow: visible;";
    setIsVisibleDesktop(false);
    setIsWebActive(false);
    setIsExtraordinaryActive(false);
    setIsMobileActive(false);
  };

  const placeNavBarBehindDesk = () => {
    document.getElementsByClassName("navBar")[0].style = "z-index: 0;";
  };

  const placeNavBarAboveDesk = () => {
    document.getElementsByClassName("navBar")[0].style = "z-index: 30;";
  };

  const removeScroll = () => {
    document.getElementsByTagName("html")[0].style = "overflow: hidden;";
  };

  const addScroll = () => {
    document.getElementsByTagName("html")[0].style = "overflow: visible;";
  };

  const openExtraordinarySpider = () => {
    setIsExtraordinaryActive(true);
    setIsVisibleDesktop(true);
  }
  
  const openMobileSpider = () => {
    setIsMobileActive(true);
    setIsVisibleDesktop(true);
  }

  const openWebSpider = () => {
    setIsWebActive(true);
    setIsVisibleDesktop(true);
  }

  const mapAreaMobile = [
    {
      width: "80%",
      height: "29%",
      left: "5%",
      top: "0%",
      style: {
        cursor: "pointer",
      }
    },
    {
      width: "100%",
      height: "26%",
      left: "0%",
      top: "70%",
      style: {
        cursor: "pointer",
      },
    },
    {
      width: "95%",
      height: "29%",
      left: "0%",
      top: "35%",
      style: {
        cursor: "pointer",
      },
    },
  ];

  const onMapClickMobile = (area, index) => {
    setIsVisibleMobile(true);
    setSpiderOverlay(index);
  };

  const mapArea = [
    !isExtraordinaryActive &&
      !isMobileActive &&
      !isWebActive && {
        width: "50%",
        height: "29%",
        left: "0%",
        top: "30.5%",
        style: {
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          zIndex: "5",
         
        },
      },

    //ExtraordinarySpiderMain

    !isExtraordinaryActive &&
      !isMobileActive &&
      !isWebActive && {
        width: "55%",
        height: "26%",
        left: "55.4%",
        top: "70%",
        style: {
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
      
        },
      },

    //MobileSpiderLayout

    isMobileActive && {
      width: "60%",
      height: "29%",
      left: "59%",
      top: "28.9%",
      style: {
        backgroundImage: "url(" + mobileSpiderGlow + ")",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      },
      render: (area, index) => (
        <>
          {isMobileActive && (
            <div class="descriptionContainer">
              <XIcon className="xIcon" />
              <div className="title">Mobile</div>
              <div
                className="text"
                id="mobileDescription"
                tabIndex="1"
              >
                According to the latest statistics the average person spends
                approximately 3 hours and 15 minutes on their phone daily.
                Having a mobile presence will allow you to take full benefits
                from all those users swiping through their phones by getting
                direct access to them. A mobile application is exactly that,
                ‘mobile’. The user will take it with him anywhere, anytime and
                you will be in permanent connection with him. Whether it is a
                companion application for your main platform or a standalone
                application, the apps we provide make sure your business is at
                maximum yield. A mobile application means free hosting, free
                marketing (both provided by the stores) and maximum mobility.
              </div>
            </div>
          )}
        </>
      ),
    },

    //MobileSpiderMain

    !isMobileActive &&
      !isWebActive &&
      !isExtraordinaryActive && {
        width: "28%",
        height: "25%",
        left: "60%",
        top: "31%",
        style: { zIndex: "4" },
        render: (area, index) => <></>,
      },

    //isExtraordinaryLayout

    isExtraordinaryActive && {
      width: "25%",
      height: "30%",
      left: "70.8%",
      top: "68.3%",
      style: {
        backgroundImage: "url(" + extraordinarySpiderGlow + ")",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      },
      render: (area, index) => (
        <>
          {isExtraordinaryActive && (
            <div class="descriptionContainerExtraordinary" onClick={(e) => e.stopPropagation()}>
              <Carousel width="170%" centerMode={false} showStatus={false} showThumbs={false}>
                <div style={{padding: "0 30px", width: "100%"}}>
                  <XIcon className="xIcon" onClick={closeOverlayDesktop} style={{cursor: "pointer"}}/>
                  <div className="title">AI & NLP</div>
                  <div
                    className="text"
                    id="extraordinaryDescription"
                    tabIndex="1"
                  >
                    Computers were invented to replace humans in doing trivial
                    tasks such that the speed and efficiency of those task is
                    greatly enhanced and the humans can be assigned to more high
                    value positions. AI comes to take this to the next level.
                    Imagine entire departments being replaced by one super-fast
                    computer script or a somewhat average-quality software being
                    taken to such a fine degree of accuracy that you would assume a
                    person is doing the task, but at the same time keep the speed
                    and precision of machines. No longer will you have to gather
                    feedback for months and then have entire teams of people
                    reviewing them in order to compute the NPS, you will just have
                    to press a button; stock trading could be done automated with
                    an undreamable yield. NLP/NLU and ML in general have a great
                    still untaped potential. Here at SpiderLinked we tap into it to
                    do all this, and more…
                  </div>
                </div>
                <div style={{padding: "0 30px", width: "100%"}}>
                  <XIcon className="xIcon" onClick={closeOverlayDesktop} style={{cursor: "pointer"}}/>
                  <div className="title">Data Analysis & Visualization</div>
                  <div
                    className="text"
                    id="extraordinaryDescriptionTab2"
                    tabIndex="2"
                  >
                    Data is one of the most important tools of a company. This is why it's proppert analysis 
                    as well as potential visualizations can be crucial and tremendously helpful for your business.
                    Our skilled developers in collaboration with the very talented teams of our partner <a href="https://d3insight.com/">D3Insight </a>
                    can make sure that you are getting the most out of your data as well as develop software for all of your data needs (including it's collection).  
                  </div>
                </div>
              </Carousel>
            </div>
          )}
        </>
      ),
    },

    //WebLayout

    isWebActive && {
      width: "25%",
      height: "34%",
      left: "11.8%",
      top: "27.9%",
      style: {
        backgroundImage: "url(" + webSpiderGlow + ")",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      },
      render: (area, index) => (
        <>
          {isWebActive && (
            <div class="descriptionContainerWeb">
              <div className="title">
                <span>Web</span>
                <XIcon className="xIcon" />
              </div>
              <div
                className="text"
                id="webDescription"
                tabIndex="1"
              >
                Due to the evolution of technologies, web applications are
                becoming more and more popular among both businesses and
                individuals and are slowly starting to replace the classical
                desktop programs. Whether it is a full-fledged PWA or just a
                simple presentation website, web applications allow you to
                reach a vast number of potential users easily without having to
                worry about your target audience’s device compatibility. Web
                apps run in browser so platform doesn’t matter and they can be
                very easily adjusted to fit any screen size.
              </div>
            </div>
          )}
        </>
      ),
    },
    !isVisibleDesktop && {
      width: "20%",
      height: "29%",
      left: "57%",
      top: "30%",
      render: (area, index) => (
        <>
          <>
            <div className="seeMoreDesktopMobileSpider">
              {" "}
              <span className="title">Mobile</span>
              <div className="icon">
                <span onClick={openMobileSpider}>SEE MORE</span>
                <SeeMoreIcon style={{ width: "18%", height: "auto" }} />
              </div>
            </div>
          </>
        </>
      ),
    },
    !isVisibleDesktop && {
      width: "20%",
      height: "29%",
      left: "0%",
      top: "30%",
      render: (area, index) => (
        <>
          <>
            <div className="seeMoreDesktopWeb">
              {" "}
              <span className="title">Web</span>
              <div onClick={openWebSpider} className="icon">
                <span >SEE MORE</span>
                <SeeMoreIcon style={{ width: "20%", height: "auto" }} />
              </div>
            </div>
          </>
        </>
      ),
    },
    !isVisibleDesktop && {
      width: "30%",
      height: "20%",
      left: "51%",
      top: "70%",

      render: (area, index) => (
        <>
          <>
            <div className="seeMoreDesktopExtraordinary">
              {" "}
              <span className="title">AI/NLP/Data Analysis & Visualization</span>
              <div onClick={openExtraordinarySpider} className="icon">
                <span>SEE MORE</span>
                <SeeMoreIcon style={{ width: "20%", height: "auto" }} />
              </div>
            </div>
          </>
        </>
      ),
    },
  ];

  const onMapClick = (area, index) => {
    if (index === 1) {
        openExtraordinarySpider()
        setTimeout(() => {
          document?.getElementById("extraordinaryDescription")?.focus();
        }, [1000]);
    }

    if (index === 3) {
        openMobileSpider()
        setTimeout(() => {
          document?.getElementById("mobileDescription")?.focus();
        }, [1000]);
    }

    if (index === 0) {
        openWebSpider()
        setTimeout(() => {
          document?.getElementById("webDescription")?.focus();
        }, [1000]);
    }
  };

  useEffect(() => {
    isVisibleMobile ? removeScroll() : addScroll();
  }, [isVisibleMobile]);

  useEffect(() => {
    isVisibleDesktop && placeNavBarBehindDesk();
    !isVisibleDesktop && placeNavBarAboveDesk();
  }, [isVisibleDesktop, !isVisibleDesktop]);

  return {
    isVisibleMobile,
    closeOverlay,
    spiderDesk,
    mapArea,
    onMapClick,
    isMobileActive,
    isWebActive,
    isExtraordinaryActive,
    removeScroll,
    addScroll,
    mapAreaMobile,
    onMapClickMobile,
    isVisibleDesktop,
    closeOverlayDesktop,
    spiderOverlay,
    placeNavBarBehindDesk,
    placeNavBarAboveDesk,
  };
};
