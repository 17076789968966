import PageTitle from "../../components/PageTitle/PageTitle";
import { Carousel } from 'react-responsive-carousel';
import { useHowWeHelp } from "./hooks";
import "./styles/HowWeHelp.scss";

export const HowWeHelp = () => {
    const { slides } = useHowWeHelp();

    const renderSlide = ({title, description}) => (
      <div style={{padding: "30px 50px", width: "100%", minHeight: 477}}>
        <span className="subTitle">{title}</span>
        <div className="description">{description}</div>
      </div>
    )

    return (
      <section id="howwehelp" className="HowWeHelp">
        <div className="howWeHelpTitle">
        <PageTitle
            description="How we can help you"
          />
        </div>
        <main className="howWeHelpDescription desktop">
          <Carousel centerMode={false} showStatus={false} showThumbs={false}>
            {slides?.map(slide => renderSlide(slide))}
          </Carousel>
        </main>
        <main className="howWeHelpDescription mobile">
          <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50} dynamicHeight showStatus={false} showThumbs={false} showArrows={false}>
            {slides?.map(slide => renderSlide(slide))}
          </Carousel>
        </main>
      </section>
    );
  };
