import "./styles/CirclesMobile.scss";

export const CirclesMobile = () => {
  return (
    <div className="CirclesMobile" style={{overflow: "visible"}}>
      <div
        style={{ height: "100vh", width:"100%", position: "relative", overflowX:"clip"}}
      >
        <div>
          <div className="pageTitle">
            CREATIVE SOFTWARE <br /> SOLUTIONS
          </div>
          <div className="pageSubTitle">
            Linking <br /> the future <br /> with the present
          </div>

          <svg
            className="planetsMobile"
            fill="none"
            viewBox="0 0 701 755"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#b)">
              <path
                d="m268.04 339.54c25.426 5.227 50.275-11.148 55.501-36.573 5.227-25.426-11.148-50.275-36.573-55.501-25.426-5.227-50.275 11.148-55.501 36.573-5.227 25.426 11.148 50.275 36.573 55.501z"
                fill="url(#a)"
              />
            </g>
            <path
              className="rotatingCircle"
              d="m572.09 556.19c70.928-109.54 39.629-255.83-69.908-326.76s-255.83-39.629-326.76 69.908-39.629 255.83 69.908 326.76 255.83 39.629 326.76-69.908z"
              stroke="#A247E6"
              strokeDasharray="100 20 10 40 180 40"
            />
            <path
              className="rotatingCircle"
              d="m168.77 375.42c93.208 20.923 185.73-37.675 206.66-130.88 20.923-93.208-37.675-185.73-130.88-206.65-93.208-20.924-185.73 37.675-206.65 130.88-20.924 93.208 37.675 185.73 130.88 206.66z"
              stroke="#26E0D5"
              strokeDasharray="100 20 10 40 180 40"
            />
            <defs>
              <filter
                id="b"
                x="222.49"
                y="238.49"
                width="110.02"
                height="110.02"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="4" />
                <feColorMatrix values="0 0 0 0 0.954167 0 0 0 0 0.25842 0 0 0 0 0.25842 0 0 0 1 0" />
                <feBlend
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_1_39"
                />
                <feBlend
                  in="SourceGraphic"
                  in2="effect1_dropShadow_1_39"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="a"
                x1="286.96"
                x2="268.04"
                y1="247.46"
                y2="339.54"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#008EF0" offset="0" />
                <stop stopColor="#39f" stopOpacity="0" offset="1" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};
