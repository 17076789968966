import { useEffect } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { gsap } from "gsap";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export const useAfterIntroLayout = () => {
  const SlideRight = () => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".animationsTrigger",
          start: "9.5% 38%",
          end: "16% 15%",
          scrub: 0.1,
        },
      })
      .to(".planets", {
        x: "250%",
        y: "20%",
        opacity: "0",
        scale: 0.4,
      });
  };
  const SlideLeft = () => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".animationsTrigger",
          start: "9.5% 38%",
          end: "16% 15%",
          scrub: 0.1,
        },
      })
      .to(".circlesRelativeDiv", {
        x: "-40%",
        y: "175%",
        opacity: "0",
        scale: 0.4,
      });
  };

  const SlideRightMobile = () => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".animationsTrigger",
          start: "4.5% 23%",
          end: "17.5% 2%",
          scrub: true,
          force3D: false,
          smoothOrigin: true,
          ease: "100%",
        },
      })
      .to(".planetsMobile", {
        x: "100%",
        opacity: "0",
        y: 400,
        z: 0,
      });
  };

  const SlideLeftMobile = () => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".animationsTrigger",
          start: "4.5% 23%",
          end: "17.5% 2%",
          scrub: 0.1,
          force3D: false,
          smoothOrigin: true,
          ease: "100%",
        },
      })
      .to(".pageSubTitle", {
        x: "-110%",
        y: 300,
        opacity: "0",
        z: 0,
      });
  };

  const FadeScrollIcon = () => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".animationsTrigger",
          start: "6% 25%",
          end: "13% 15%",
          scrub: 0.1,
        },
      })
      .to(".scrollContainer", {
        opacity: "0",
      });
  };

  useEffect(() => {
    SlideRightMobile();
    SlideLeftMobile();

    setTimeout(() => {
      SlideRight();
      SlideLeft();
      FadeScrollIcon();
    }, 4000);

    window.history.scrollRestoration = "manual";
  }, []);

  return {
    SlideRight,
  };
};
