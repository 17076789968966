import "./styles/Menu.scss";
import { ReactComponent as Clock } from "../../../../assets/seeMoreMobileSvg.svg";
import { ReactComponent as Facebook } from "../../../../assets/fbicon.svg";
import { ReactComponent as Twitter } from "../../../../assets/twitter.svg";
import { ReactComponent as LinkedIn } from "../../../../assets/linkdin.svg";
import { ReactComponent as GitHub } from "../../../../assets/github.svg";

const Menu = ({ mobileMenu, isOnOverlay, toggleHamburgerMenu }) => {
  
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <div className={isOnOverlay ? "MenuContainerOverlay" : "MenuContainer"}>
        <div className={isOnOverlay ? "menuOverlay" : "menu"}>
          <div className={mobileMenu ? "mobile" : "desktop"}>
            <ul className="itemsContainer">
              <div className="contactBtn">
                <div className="desktopB" aria-hidden="true">
                  <a className="contact" href="#contactUsForm">
                    Contact Us <Clock className="clock"></Clock>
                  </a>
                </div>
              </div>
              <li role="presentation" className="circle active">
                <div className="desktop">
                  <a
                    role="presentation"
                    href="#HomePage"
                    aria-label="Home Page"
                  >
                    Home
                  </a>
                </div>
                <div className="mobileLink">
                  <a
                    onClick={toggleHamburgerMenu}
                    role="presentation"
                    href="#HomePage"
                    aria-label="Home Page"
                  >
                    Home
                  </a>
                </div>
              </li>
              <li className="howwehelp">
                <div className="desktop">
                  <a aria-label="How we help" href="#howwehelp">
                    How we help
                  </a>
                </div>
                <div className="mobileLink">
                  <a
                    onClick={toggleHamburgerMenu}
                    aria-label="How we help"
                    href="#howwehelp"
                  >
                    How we help
                  </a>
                </div>
              </li>
              <li className="whatwedo">
                <div className="desktop">
                  <a aria-label="What We Do" href="#whatwedo">
                    What we do
                  </a>
                </div>
                <div className="mobileLink">
                  <a
                    onClick={toggleHamburgerMenu}
                    aria-label="What We Do"
                    href="#whatwedo"
                  >
                    What we do
                  </a>
                </div>
              </li>
              <li className="techstack">
                <div className="desktop">
                  <a aria-label="Tech stack" href="#techstack">
                    Tech Stack
                  </a>
                </div>
                <div className="mobileLink">
                  <a
                    onClick={toggleHamburgerMenu}
                    aria-label="Tech stack"
                    href="#techstack"
                  >
                    Tech Stack
                  </a>
                </div>
              </li>
              <li className="partners">
                <div className="desktop">
                  {" "}
                  <a aria-label="Partners" href="#partners">
                    Partners
                  </a>
                </div>
                <div className="mobileLink">
                  {" "}
                  <a
                    onClick={toggleHamburgerMenu}
                    aria-label="Partners"
                    href="#partners"
                  >
                    Partners
                  </a>
                </div>
              </li>
              <li className="contactus">
                <div className="desktop">
                  <a aria-label="About us" href="#contactus">
                    About us
                  </a>
                </div>
                <div className="mobileLink">
                  <a
                    onClick={toggleHamburgerMenu}
                    aria-label="About us"
                    href="#contactus"
                  >
                    About us
                  </a>
                </div>
              </li>
              <div className="contactBtn">
                <div onClick={toggleHamburgerMenu} className="mobileB">
                  <a className="contact" href="#contactUsForm">
                    Contact Us
                  </a>{" "}
                  <Clock className="clock" />
                </div>
              </div>
              <div>
                <div className="socialsContainer">
                  <div className="socials">
                    <Facebook
                      onClick={() =>
                        openInNewTab(
                          "https://www.facebook.com/SpiderLinked-100359925423434/"
                        )
                      }
                    />
                    <Twitter
                      onClick={() =>
                        openInNewTab("https://twitter.com/SpiderLinked")
                      }
                    />
                    <LinkedIn
                      onClick={() =>
                        openInNewTab(
                          "https://www.linkedin.com/company/spiderlinked"
                        )
                      }
                    />
                    <GitHub
                      onClick={() =>
                        openInNewTab(
                          "https://github.com/orgs/SpiderLinked/dashboard"
                        )
                      }
                    />
                  </div>
                  <div className="footer">©2021 SpiderLinked</div>
                </div>
              </div>
              <li className="slide"></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Menu;
