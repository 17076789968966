import { useCallback, useState, useRef, useEffect } from "react";

export const usePartners = () => {
  const smallHexagonShape = "9%";
  const mediumHexagonShape = "11%";
  const largeHexagonShape = "13%";
  const hexagonShapeMobile = "22%";

  const popUpRef = useRef();

  const [openCompanyId, setOpenCompanyId] = useState(null);
  const [isFaded, setIsFaded] = useState(false);

  const toggle = useCallback(
    (e, companyid) => {
      if (openCompanyId === companyid || !popUpRef) {
        setIsFaded(false);
        return setOpenCompanyId(null);
      }
      setIsFaded(true);

      setTimeout(() => {
        setOpenCompanyId(companyid);
      }, 80);
    },
    [setOpenCompanyId, openCompanyId]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef && !popUpRef?.current?.contains(event.target)) {
        setIsFaded(false);
        return setOpenCompanyId(null);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popUpRef]);

  return {
    openCompanyId,
    smallHexagonShape,
    mediumHexagonShape,
    largeHexagonShape,
    hexagonShapeMobile,
    toggle,
    isFaded,
    popUpRef,
  };
};
