import "./styles/CirclesDesktop.scss";

export const CirclesDesktop = ({}) => {
  return (
    <section className="CirclesDesktop" id="circle" style={{height: "100vh", position: "relative"}}>
      <div className="circlesContainer">
        <div className="circlesRelativeDiv ">
          <div className="title">
            CREATIVE SOFTWARE <br /> SOLUTIONS
          </div>
          <div className="subTitle">
            Linking <br /> the future <br /> with the present
            <svg
              className="planets"
              width="60vw"
              height="230%"
              viewBox="0 0 701 755"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Group 1">
                <g id="Circles">
                  <g id="Ellipse 24" filter="url(#filter0_d_1_39)">
                    <path
                      id="Small_circle"
                      d="M268.037 339.538C293.463 344.765 318.312 328.39 323.538 302.965C328.765 277.539 312.39 252.69 286.965 247.464C261.539 242.237 236.69 258.612 231.464 284.037C226.237 309.463 242.612 334.312 268.037 339.538Z"
                      fill="url(#paint0_linear_1_39)"
                    />
                  </g>
                </g>
                <path
                  id="Purple"
                  className="rotatingCircle"
                  d="M572.094 556.186C643.022 446.649 611.723 300.354 502.186 229.426C392.649 158.498 246.354 189.797 175.426 299.334C104.498 408.871 135.797 555.166 245.334 626.094C354.871 697.022 501.167 665.723 572.094 556.186Z"
                  stroke="#A247E6"
                  strokeDasharray="100 20 10 40 180 40"
                />
                <path
                  id="Blue"
                  className="rotatingCircle"
                  d="M168.769 375.424C261.977 396.347 354.5 337.749 375.424 244.54C396.347 151.332 337.749 58.8095 244.54 37.8858C151.332 16.962 58.8095 75.5604 37.8858 168.769C16.962 261.977 75.5604 354.5 168.769 375.424Z"
                  stroke="#26E0D5"
                  strokeDasharray="100 20 10 40 180 40"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_1_39"
                  x="222.492"
                  y="238.492"
                  width="110.019"
                  height="110.019"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="4" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.954167 0 0 0 0 0.25842 0 0 0 0 0.25842 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1_39"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1_39"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_1_39"
                  x1="286.965"
                  y1="247.464"
                  x2="268.037"
                  y2="339.538"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#008EF0" />
                  <stop offset="1" stopColor="#3399FF" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
            
          </div>
          
        </div>
        
      </div>
      <div className="scrollContainer">
        <div className="scrollText">SCROLL</div>
        <div
          className="iconScroll"
        ></div>
      </div>
    </section>
  );
};
