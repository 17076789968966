import "./App.css";
import IntroScreen from "./screens/IntroScreen/IntroScreen";
import React from "react";
import { AfterIntroLayout } from "./screens/AfterIntroLayout";
import { useApp } from "./hooks";
import "react-responsive-carousel/lib/styles/carousel.min.css";


const App = () => {

  const {shouldStartFadingLogo, isAfterIntroLayout, isIntroScreenVisible} = useApp()

  return (
    <div>
      {isIntroScreenVisible === true ? (
        <IntroScreen className={shouldStartFadingLogo ? "fading" : ""} />
      ) : null}
      {isAfterIntroLayout === true && (
        <AfterIntroLayout/>
      )}
    </div>
  );
};
export default App;
