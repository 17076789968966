import { CirclesDesktop } from "./components/CirclesDesktop/CirclesDesktop";
import { CirclesMobile } from "./components/CirclesMobile/CirclesMobile";
import "./styles/HomePage.scss"

export const HomePage = () => {
  return (
    <div className="homePage">
      <div className="desktop">
        <CirclesDesktop />
      </div>
      <div className="mobile">
        <CirclesMobile />
      </div>
    </div>
  );
};
