import React from "react";
import "./styles/OverlayMobile.scss";
import NavBar from "../../components/NavBar/NavBar";
import { SpidersDescription } from "./Content";


export default function OverlayMobile({ onClick, spider }) {
  return (
    <div className={"overlayContainer"}>
     <NavBar isOnOverlay onClick={onClick} />
      <div className="overall">
        <div>
          <div className="overlayTextContainer">
            <img src={SpidersDescription[spider].image} alt="spider"/>
            <div className="overlayTitle">{SpidersDescription[spider].title}</div>
            <div className="overlayText">
            {SpidersDescription[spider].description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
