import { useState, useCallback } from "react";

export const useHexagon = (isMobile, toggle, companyid, href) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFaded, setIsFaded] = useState(false);
  const removeScroll = () => {
    document.getElementsByTagName("html")[0].style = "overflow: hidden;";
  };

  const addScroll = () => {
    document.getElementsByTagName("html")[0].style = "overflow: visible;";
  };

  const openOverlayMobile = useCallback(() => {
    setIsOpen(true);
    removeScroll();
  }, [isOpen]);

  const closeOverlayMobile = useCallback(() => {
    setIsOpen(false);
    addScroll();
  }, [isOpen]);

  const toggleHexagon = useCallback(
    (e) => {
      e.stopPropagation();
      !isOpen && toggle?.(e, companyid);
    },
    [isOpen, toggle]
  );

  const onContentClick = useCallback(e => {
    if (href) {
      e.stopPropagation();
      window.location.href = href;
    }
  }, [href])

  return {
    openOverlayMobile,
    closeOverlayMobile,
    setIsFaded,
    isFaded,
    isOpen,
    toggleHexagon,
    onContentClick,
  };
};
