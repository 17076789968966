import { useState, useCallback, useEffect } from "react";

export const useNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleHamburgerMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const scrollToTop = () => window.scrollTo(0, 0);

  useEffect(() => {
    const li = document.querySelectorAll("li");
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {

      var current = "";

      sections.forEach((section) => {
        const sectionHeight = section.clientHeight;
        const sectionTop = section.offsetTop;
        if (window.pageYOffset > sectionTop - sectionHeight / 2) {
          current = section.getAttribute("id");
        }
      });

      li.forEach((li) => {
        if (li.classList.contains(current)) {
          li.classList.add("active");
        } else {
          li.classList.remove("active");
        }
      });
    });
  }, []);

  return {
    isOpen,
    toggleHamburgerMenu,
    scrollToTop,
  };
};
