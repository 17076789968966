import { useHexagon } from "./hooks";
import "./styles/Hexagon.scss";
import HexagonTile from "../../../../assets/hexagons/JS.png";
import OverlayPartners from "../../../Overlays/OverlayPartners";
import xIcon from "../../../../assets/hexagons/xIcon.svg";

export const Hexagon = ({
  logo,
  left,
  top,
  width,
  popUpLogo,
  isOnEdge,
  marginTop,
  description,
  title,
  isMobile,
  companyid,
  toggle,
  openCompanyId,
  isFaded,
  paddingTop,
  zIndex,
  position,
  isEmpty,
  popUpRef,
  href,
}) => {
  const { closeOverlayMobile, openOverlayMobile, isOpen, toggleHexagon, onContentClick } =
    useHexagon(isMobile, toggle, companyid, href);

  return (
    <div
      style={{
        height: "100%",
        width: width,
        position: "relative",
        left: left,
        paddingTop: paddingTop,
        marginTop: marginTop,
      }}
    >
      <div
        className="popUpTrigger"
        style={{
          pointerEvents:openCompanyId && openCompanyId === companyid ? "none" : "auto",
          cursor: 'pointer'
        }}
        onClick={isMobile ? openOverlayMobile : toggleHexagon}
      ></div>
      <div className="overlayPartners">
        {isOpen && (
          <OverlayPartners
            onClick={closeOverlayMobile}
            partnersTitle={title}
            partnersLogo={logo}
            partnersDescription={description}
            href={href}
            onContentClick={onContentClick}
          />
        )}
      </div>

      <div role="dialog" className={"hexagonContainer"}>
        <img
          className={`hexagonShape${
            openCompanyId && openCompanyId === companyid && isFaded
              ? "Animated "
              : ""
          } ${!isMobile && !toggle && "notClickable"}`}
          src={HexagonTile}
          alt="hexagonShape"
        ></img>

        <div
          className={`logoRelativeDiv${
            openCompanyId && openCompanyId === companyid && isFaded
              ? "Animated "
              : ""
          } ${!isMobile && !toggle && "notClickable"} ${isEmpty && "empty"} `}
        >
          {logo}
        </div>

        {openCompanyId && openCompanyId === companyid && (
          <div className="popupRelativeDiv">
            <div
              ref={popUpRef && popUpRef}
              className="popup"
              style={{
                right: !isOnEdge ? "50%" : "150%",
              }}
            >
              {/* Hexagon Pop Up Content */}
              <div className="popUpDescriptionContainer">
                <div className="popUpElements">
                  <img
                    onClick={toggleHexagon}
                    className="xIconPopUp"
                    src={xIcon}
                    alt="x"
                  />
                  <div className="logoPopUp" style={{cursor: href ? "pointer" : "default"}} onClick={onContentClick}>{popUpLogo}</div>
                  <div className="popUpDescription" style={{cursor: href ? "pointer" : "default"}} onClick={onContentClick}>{description}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
