import PageTitle from "../../components/PageTitle/PageTitle";
import "./styles/Partners.scss";
import { ReactComponent as FintechLogo } from "../../assets/hexagons/FintechLogo.svg";
import { ReactComponent as FintechLogoPopUp } from "../../assets/hexagons/FintechLogoPopUp.svg";
import { ReactComponent as ForagaLogo } from "../../assets/hexagons/ForagoLogo.svg";
import { ReactComponent as ForagaPopUpLogo } from "../../assets/hexagons/ForagaPopUpLogo.svg";
import { ReactComponent as RodeAppsPopUpLogo } from "../../assets/hexagons/RodeAppsPopUpLogo.svg";
import { ReactComponent as RodeApps } from "../../assets/hexagons/RodeApps.svg";
import { ReactComponent as RentInsiderLogo } from "../../assets/hexagons/RentInsiderLogo.svg";
import { ReactComponent as PolyConseilPopUp } from "../../assets/hexagons/PolyConseilPopUp.svg";
import { ReactComponent as PolyConseilLogo } from "../../assets/hexagons/PolyConseilsvg2.svg";
import { ReactComponent as RentInsiderLogoWhite } from "../../assets/hexagons/RentInsiderLogoWhite.svg";
import LinkGraphPng from "../../assets/hexagons/LinkGraphPng.png";
import { ReactComponent as TacitKnowledgeLogo } from "../../assets/hexagons/TacitKnowledgeLogo.svg";
import { ReactComponent as TacitKnowledgePopUpLogo } from "../../assets/hexagons/TacitKnowledgePopUpLogo.svg";
import { ReactComponent as LinkGraphLogo } from "../../assets/hexagons/LinkGraphLogo.svg";
import {ReactComponent as PinnacleLogo} from "../../assets/hexagons/PinnacleLogo.svg";
import {ReactComponent as PinnaclePopUpLogo} from "../../assets/hexagons/PinnaclePopUpLogo.svg"
import { Hexagon } from "./components/Hexagon/Hexagon";
import { usePartners } from "./hooks";
import { HexagonsDescription } from "./Content";

export const Partners = () => {
  const {
    smallHexagonShape,
    largeHexagonShape,
    mediumHexagonShape,
    hexagonShapeMobile,
    toggle,
    isFaded,
    popUpRef,
    openCompanyId,
  } = usePartners();

  return (
    <section
      id="partners"
      className="partnersContainer"
      style={{ position: "relative"}}
    >
      <div className="partnersTitle">
        <PageTitle
          title="PARTNERS"
          description="The amazing teams we are working with"
        />
      </div>

      <div className="hexagonsRelativeDiv">
        <div className="hexagonsRow first">
          <Hexagon
            width={mediumHexagonShape}
            isFaded={isFaded}
            companyid="1"
            description={HexagonsDescription?.[2]?.description}
            openCompanyId={openCompanyId}
            paddingTop="0"
            left={"55%"}
            marginTop="0%"
            logo={"We are eager to have you in this slot"}
            isEmpty
            popUpRef={popUpRef}
          />
        </div>

        <div className="hexagonsRow second">
          <Hexagon
            marginTop="5%"
            width={largeHexagonShape}
            left={"60%"}
            description={HexagonsDescription?.[8]?.description}
            logo={<PinnacleLogo className="logoSvg" />}
            popUpLogo={<PinnaclePopUpLogo className="popUpLogoSvg pinnacle" />}
            companyid="2"
            openCompanyId={openCompanyId}
            toggle={toggle}
            isFaded={isFaded}
            popUpRef={popUpRef}
            href="https://www.snowsos.com"
          />

          <Hexagon
            marginTop="2%"
            width={largeHexagonShape}
            left={"30%"}
            description={HexagonsDescription?.[1]?.description}
            logo={<ForagaLogo className="logoSvg" />}
            popUpLogo={<ForagaPopUpLogo className="popUpLogoSvg" />}
            companyid="3"
            openCompanyId={openCompanyId}
            toggle={toggle}
            isFaded={isFaded}
            popUpRef={popUpRef}
            href="https://www.foraga.com"
          />

          <Hexagon
            marginTop="8%"
            companyid="4"
            width={smallHexagonShape}
            toggle={toggle}
            openCompanyId={openCompanyId}
            description={HexagonsDescription?.[7]?.description}
            isFaded={isFaded}
            logo={<PolyConseilLogo className="logoSvg polyConseil" />}
            popUpLogo={<PolyConseilPopUp className="popUpLogoSvg polyconseil" />}
            left={"5%"}
            popUpRef={popUpRef}
            href="https://www.polyconseil.fr"
          />

          <Hexagon
            marginTop="1%"
            logo={<FintechLogo className="logoSvg" />}
            popUpLogo={<FintechLogoPopUp className="popUplogoSvg" />}
            description={HexagonsDescription?.[3]?.description}
            width={largeHexagonShape}
            left={"40%"}
            companyid="5"
            openCompanyId={openCompanyId}
            toggle={toggle}
            isFaded={isFaded}
            popUpRef={popUpRef}
            href="https://www.deutschefintechsolutions.de"
          />
        </div>

        <div className="hexagonsRow third">
          <Hexagon
            companyid="6"
            marginTop="3%"
            width={largeHexagonShape}
            toggle={toggle}
            openCompanyId={openCompanyId}
            isFaded={isFaded}
            logo={<TacitKnowledgeLogo className="logoSvg" />}
            popUpLogo={<TacitKnowledgePopUpLogo className="popUpLogoSvg tacitKnowledge" />}
            title={HexagonsDescription?.[6]?.title}
            description={HexagonsDescription?.[6]?.description}
            left={"70%"}
            popUpRef={popUpRef}
            href="https://www.tacitknowledge.com"
          />

          <Hexagon
            marginTop="5%"
            width={largeHexagonShape}
            toggle={toggle}
            companyid="7"
            openCompanyId={openCompanyId}
            zIndex="5"
            isFaded={isFaded}
            logo={
              <img
                src={LinkGraphPng}
                alt="linkgraph"
                className="logoSvg linkGraph"
              />
            }
            popUpLogo={
              <img
                src={LinkGraphPng}
                alt="linkgraph"
                className="popUpLogoSvg linkGraph"
              />
            }
            description={HexagonsDescription?.[4]?.description}
            left="40%"
            popUpRef={popUpRef}
            href="https://www.linkgraph.io"
          />

          <Hexagon
            marginTop="-2%"
            left="10%"
            width={largeHexagonShape}
            description={HexagonsDescription?.[5]?.description}
            logo={<RentInsiderLogoWhite className="logoSvg" />}
            popUpLogo={<RentInsiderLogo className="popUpLogoSvg" />}
            companyid="8"
            openCompanyId={openCompanyId}
            toggle={toggle}
            isFaded={isFaded}
            popUpRef={popUpRef}
            href="https://www.rent-insider.com"
          />

          <Hexagon
            width={smallHexagonShape}
            left={"-15%"}
            logo={<RodeApps className="logoSvg" />}
            popUpLogo={<RodeAppsPopUpLogo className="popUpLogoSvg" />}
            companyid="9"
            description={HexagonsDescription?.[0]?.description}     
            openCompanyId={openCompanyId}
            toggle={toggle}
            isFaded={isFaded}
            popUpRef={popUpRef}
            href="https://www.rodeapps.com"
          />
        </div>
      </div>

      <div className="hexagonsRelativeDivMobile">
        <div className="hexagonsRow1Mobile">
          <Hexagon
            width={hexagonShapeMobile}
            left={"10%"}
            title={HexagonsDescription?.[0]?.title}
            description={HexagonsDescription?.[0]?.description}
            logo={<RodeApps className="logoSvg" />}
            isMobile
            href="https://www.rodeapps.com"
          />

          <Hexagon
            marginTop="10%"
            left="14%"
            width={hexagonShapeMobile}
            logo={<ForagaLogo className="logoSvg" />}
            title={HexagonsDescription?.[1]?.title}
            description={HexagonsDescription?.[1]?.description}
            isMobile
            openCompanyId={openCompanyId}
            href="https://www.foraga.com"
          />

          <Hexagon
            marginTop="3%"
            left="20%"
            width={hexagonShapeMobile}
            logo={<PinnacleLogo className="logoSvg" />}
            title={HexagonsDescription?.[8]?.title}
            description={HexagonsDescription?.[8]?.description}
            isMobile
            openCompanyId={openCompanyId}
            href="https://www.snowsos.com"
          />
        </div>

        <div className="hexagonsRow2Mobile">
          <Hexagon
            logo={<FintechLogo className="logoSvg" />}
            title={HexagonsDescription?.[3]?.title}
            description={HexagonsDescription?.[3]?.description}
            width={hexagonShapeMobile}
            left={"10%"}
            isMobile
            openCompanyId={openCompanyId}
            href="https://www.deutschefintechsolutions.de"
          />

          <Hexagon
            logo={<LinkGraphLogo className="logoSvg" />}
            title={HexagonsDescription?.[4].title}
            description={HexagonsDescription?.[4]?.description}
            marginTop="10%"
            left="14%"
            width={hexagonShapeMobile}
            isMobile
            href="https://www.linkgraph.io"
          />

          <Hexagon
            logo={<PolyConseilLogo className="logoSvg" />}
            title={HexagonsDescription?.[7].title}
            description={HexagonsDescription?.[7].description}
            marginTop="3%"
            left="20%"
            width={hexagonShapeMobile}
            isMobile
            href="https://www.polyconseil.fr"
          />
        </div>

        <div className="hexagonsRow3Mobile">
          <Hexagon
            width={hexagonShapeMobile}
            left={"10%"}
            isMobile
            logo={<RentInsiderLogoWhite className="logoSvg" />}
            title={HexagonsDescription?.[5].title}
            description={HexagonsDescription?.[5].description}
            href="https://www.rent-insider.com"
          />

          <Hexagon
            marginTop="10%"
            left="14%"
            width={hexagonShapeMobile}
            logo={<TacitKnowledgeLogo className="logoSvg" />}
            title={HexagonsDescription?.[6].title}
            description={HexagonsDescription?.[6].description}
            isMobile
            href="https://www.tacitknowledge.com"
          />

          <Hexagon
            marginTop="3%"
            left="20%"
            width={hexagonShapeMobile}
            isMobile
            logo={"We are eager to have you in this slot"}
            isEmpty
          />
        </div>
      </div>
    </section>
  );
};
