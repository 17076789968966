import PageTitle from "../../components/PageTitle/PageTitle";

import "./styles/TechStack.scss";

export const TechStack = () => {
    return (
      <section id="techstack" className="TechStack">
        <div className="techStackTitle">
          <PageTitle title="Tech Stack" description="Technologies we use" />
        </div>
        <main className="techStackDescription">
          <div className="codingLanguages">
            <span className="subTitle">web development</span>
            <ul>
              Front-end:
              <li>JS/ Typescript</li>
              <li>React.JS/ Typescript</li>
              <li>Vue.JS/ Typescript</li>
              <li>Angular.JS/ Typescript</li>
              <li>Next.JS</li>
              <li>Nuxt.JS</li>
              <li>WebFlow</li>
            </ul>
            <ul>
              Back-end:
              <li>Python/ Django/ Flask</li>
              <li>PHP/ Laravel</li>
              <li>Node.JS (Express.JS)</li>
            </ul>
            <ul>
              Full-stack (monolith technologies):
              <li>ASP.NET </li>
              <li>Blazor + WASM</li>
              <li>Java Spring</li>
              <li>Wordpress</li>
            </ul>
            <span className="subTitle">mobile development</span>
            <ul>
              <li>React.Native</li>
              <li>Flutter</li>
              <li>Kotlin/Swift</li>
            </ul>
          </div>
          <div className="extraServices">
            <span className="subTitle">maintenance & support + legacy code maintenance</span>
            For all of the technologies listed above, as well as C or C++.
            <span className="subTitle">additional services</span>
            <ul>
              <li>Data Analysis & Visualisation</li>
              <li>UI/UX design, Video editing and promotional materials design</li>
              <li>Database Administration</li>
              <li>Azure Administration</li>
              <li>Snowflake Data-Processing</li>
              <li>Manual QA (or automated if needed)</li>
              <li>Marketing</li>
              <li>Team leading + SCRUM master (PM/BA)</li>
              <li>DevOps (via AWS, Azure or Heroku preferably but not exclusive)</li>
              <li>Technical Consulting</li>
              <li>Desktop and Embeded Applications</li>
            </ul>
          </div>
        </main>
      </section>
    );
  };
