import MobileSpider from "../../assets/spiders/theMobileSpider.png";
import WebSpider from "../../assets/spiders/theWebSpider.png";
import ExtraordinarySpider from "../../assets/spiders/theExtraordinarySpiderMobile.png";
import { Carousel } from 'react-responsive-carousel';

export const SpidersDescription = [
  {
    title: "Web",
    description:
      "Due to the evolution of technologies, web applications are becoming more and more popular among both businesses and individuals and are slowly starting to replace the classical desktop programs. Whether it is a full-fledged PWA or just a simple presentation website, web applications allow you to reach a vast number of potential users easily without having to worry about your target audience’s device compatibility. Web apps run in browser so platform doesn’t matter and they can be very easily adjusted to fit any screen size.",
    image: WebSpider,
  },

  {
    title: "AI/NLP/Data Analysis & Visualization",
    description:  <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50} dynamicHeight showStatus={false} showThumbs={false} showArrows={false}>
       <>
          Computers were invented to replace humans in doing trivial
          tasks such that the speed and efficiency of those task is
          greatly enhanced and the humans can be assigned to more high
          value positions. AI comes to take this to the next level.
          Imagine entire departments being replaced by one super-fast
          computer script or a somewhat average-quality software being
          taken to such a fine degree of accuracy that you would assume a
          person is doing the task, but at the same time keep the speed
          and precision of machines. No longer will you have to gather
          feedback for months and then have entire teams of people
          reviewing them in order to compute the NPS, you will just have
          to press a button; stock trading could be done automated with
          an undreamable yield. NLP/NLU and ML in general have a great
          still untaped potential. Here at SpiderLinked we tap into it to
          do all this, and more…
        </>
        <>
          Data is one of the most important tools of a company. This is why it's proppert analysis 
          as well as potential visualizations can be crucial and tremendously helpful for your business.
          Our skilled developers in collaboration with the very talented teams of our partner <a href="https://d3insight.com/">D3Insight </a>
          can make sure that you are getting the most out of your data as well as develop software for all of your data needs (including it's collection).  
        </>
    </Carousel>,
    image: ExtraordinarySpider,
  },

  {
    title: "Mobile",
    description:
      " According to the latest statistics the average person spends approximately 3 hours and 15 minutes on their phone daily. Having a mobile presence will allow you to take full benefits from all those users swiping through their phones by getting direct access to them. A mobile application is exactly that, 'mobile'. The user will take it with him anywhere, anytime and you will be in permanent connection with him. Whether it is a companion application for your main platform or a standalone application, the apps we provide make sure your business is at maximum yield.  A mobile application means free hosting, free marketing (both provided by the stores) and maximum mobility.",
    image: MobileSpider,
  },
];
